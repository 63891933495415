import useAsyncFetch from "./useAsyncFetch";

const gameName = ref("");
const isResponse = ref(false);

const [isExpanded, toggleExpand] = useToggle(false);

const useGameSearch = () => {
	const fetchOptions = () => ({
		query: { name: gameName.value.trim() },
		onResponse: () => {
			isResponse.value = true;
		},
		onResponseError: () => {
			isResponse.value = true;
		}
	});

	const {
		data: dataGameSearch,
		pending: isLoading,
		execute
	} = useAsyncFetch({
		path: "/rest/search/",
		method: "get",
		options: {
			immediate: false
		},
		fetchOptions
	});

	const clearSearch = () => {
		gameName.value = "";
		isResponse.value = false;
		clearNuxtData("/rest/search/");
	};

	const handleClickExpand = (value: boolean) => {
		toggleExpand(value);

		if (!value) {
			clearSearch();
		}
	};

	const handleSearch = useDebounceFn((value: string) => {
		if (!value) {
			return clearSearch();
		}

		execute();
	}, 500);

	return {
		dataGameSearch,
		gameName,
		isLoading,
		isResponse,
		isExpanded,
		handleSearch,
		handleClickExpand,
		clearSearch
	};
};

export default useGameSearch;
